// Do redmode?

let redMode = (localStorage.redMode=='1')?true:false;
//console.warn(localStorage.redMode);
//console.warn("LOCAL REDMODE???",redMode);
if (redMode){
    let htmlElement = document.querySelector('html');
    htmlElement.className='redmode';
}
import Cookies from 'js-cookie';
// var c = Cookies.get("preload");
//console.log("Current location:||",window.location.pathname,"||",window.location.href,window.location.search,window.location);
if (window.location.pathname == "/" && window.location.search=="" && Cookies.get("gtoken")){
    //console.log("GUEST SHOULDNT BE HERE",{path:window.location.pathname,query:window.location.search,cookie:Cookies.get("gtoken")});
    Cookies.remove("gtoken");
    window.location.reload();
}
//console.log("GUEST-TOKEN:",Cookies.get("gtoken"));
//console.log("FA-TOKEN:",Cookies.get("fatoken"));
// window.preloadCookie = Cookies.get("preload");
if (window.preloadCookie=Cookies.get("preload")){
    //console.log("PRELOAD COOKIE DETECTED");//,window.preloadCookie);
    Cookies.remove("preload");
}

import Vue from "vue";
// import Vue2TouchEvents from 'vue2-touch-events'
// Vue.use(Vue2TouchEvents);
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "./assets/styles/main.css";
import "./assets/styles/positioning.css";
import "./assets/styles/effects.css";
import "./assets/styles/redmode.css";
import FirstAlertVue from "./plugins/FirstAlertVue";
Vue.use(FirstAlertVue, {});
Vue.config.productionTip = false;
// import VueTapMixin from 'vue-tap-mixin'
// Vue.use(VueTapMixin);
// Vue.component('HUDPanel',() => import('@/components/widgets/HUDPanel'));


const canBeGuest = (window.location.pathname=='/' && window.location.search.startsWith("?a="))

//console.warn("Can be a guest?",canBeGuest);
window.canBeGuest = canBeGuest;
// Register global VUE components:
import {     
    LPolygon,
    LMap,
    LIcon,
    LTileLayer, 
    LMarker,
    LPopup,
    LTooltip,
    LControl,
    LControlZoom,
    LCircle
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-popup', LPopup);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control', LControl);
Vue.component('l-icon', LIcon);
Vue.component('l-polygon', LPolygon);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
async function initLeaflet(){   
    Icon.Default.mergeOptions({
        iconRetinaUrl: await (await import('leaflet/dist/images/marker-icon-2x.png')).default,
        iconUrl: await (await import('leaflet/dist/images/marker-icon.png')).default,
        shadowUrl: await (await import('leaflet/dist/images/marker-shadow.png')).default,
      });
}

initLeaflet();
// window.L = L;
// Leaflet imports:
// window.L = L;
// window.L.test = 'START';
import "../lib/leaflet/leaflet.canvas.markers.mjs";
import "../lib/leaflet/leaflet.edgebuffer";
import "leaflet-geometryutil";//"leaflet.geometryutil";
import * as esri from "esri-leaflet";//"leaflet.geometryutil";
// import "../lib/leaflet/leaflet.esri-leaflet.cjs";
// console.warn("ESRI??",esri);
if (window.L){
    window.L.esri = esri;
}

FirstAlertVue.app.onBoot(()=>{
    //console.warn("Loaded SYSTEM:", FirstAlertVue);

    const vm = new Vue({
        vuetify,
        render: (h) => h(App)
    }).$mount("#app");    
    FirstAlertVue.app.ui.init(vm);
});

